import React, {type MouseEventHandler, type ReactElement, useState} from "react";
import {Button, type ButtonProps, Modal} from "react-bootstrap";

type ConfirmationRequiredButtonProps = Omit<ButtonProps, "onClick" | "as"> & {
    title?: string,
    detail?: string | ReactElement,
    confirm?: string,
    cancel?: string,
    onConfirm: MouseEventHandler<HTMLButtonElement>,
    [key: string]: any,
}
export function ConfirmationRequiredButton({title = "Confirmation", detail, confirm = "Yes", cancel = "No", onConfirm, ...props}: ConfirmationRequiredButtonProps): ReactElement {
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    return <>
        <Button onClick={() => setShowModal(true)} {...props}>{props.children}</Button>

        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            {detail ? <Modal.Body>{detail}</Modal.Body> : undefined}
            <Modal.Footer>
                <Button accessKey={"n"} id="confirmation-no" variant="secondary" onClick={handleClose}>{cancel}</Button>
                <Button accessKey={"y"} id="confirmation-yes" variant="primary" onClick={onConfirm}>{confirm}</Button>
            </Modal.Footer>
        </Modal>
    </>
}